import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure
} from "@chakra-ui/react"
import { useApprove } from "../hooks"
import { projectContractAddress } from "../contracts"; 


export default function ApproveModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const limitValue: string = '115792089237316195423570985008687907853269984665640564039457584007913129639935' // 無制限

  // approveメソッド
  const { state, send: submitApprove } = useApprove('', '')

  // クリックするとapprove
  async function handleOnClick(e: any) {
    e.preventDefault()
    await submitApprove(projectContractAddress, limitValue)
  }

  return (
    <>
      <Button onClick={onOpen}>Approve</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalBody>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleOnClick}>Approve</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}