import { useState } from 'react'
import "react-datepicker/dist/react-datepicker.css"
import { Formik, Form, Field, FieldProps } from 'formik'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ModalBody,
  useDisclosure,
  Box,
  Select
} from "@chakra-ui/react"
import { useSetEvent } from "../hooks"
import { DatePickerField } from "./DatePicker"
import { BigNumber } from 'bignumber.js'

// 初期値
const initialSetEventValues = {
  eventName: '',
  depositToken: '',
  hardCap: '',
  maxConribute: '',
  minContribute: '',
  fCFSTimer: ''
}

export default function CreateEventModal(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // 値がdecimal18桁なのでこの定数で割って調整
  const convertedNum = 10**6
  const account = props.account

  // SetEventメソッド準備
  const { state, send: sendSetEvent } = useSetEvent()

  // セレクトの値管理
  const [ eventHourState, setEventHourState ] = useState(0)

  // セレクトのオプション作成
  const options: any = [];
  for (let i=0; i < 24; i++) options.push(i);

  // イベント作成（SetEvent）
  async function handleSetEvent(value: any) {
    const eventName = value.eventName
    const depositToken = value.depositToken
    const fCFSTimer = value.fCFSTimer
    const convertedToUnixTime = fCFSTimer ? fCFSTimer.getTime()/1000: null
    const hardCap = new BigNumber(value.hardCap * convertedNum)
    const stringfiedHardCap = hardCap.toFixed().toString()
    const maxContribute = new BigNumber(value.maxContribute * convertedNum)
    const stringfiedMaxContribute = maxContribute.toFixed().toString()
    const minContribute = new BigNumber(value.minContribute * convertedNum)
    const stringfiedMinContribute = minContribute.toFixed().toString()

    console.log(convertedToUnixTime)
    await sendSetEvent(
      eventName, 
      depositToken,  
      stringfiedHardCap, 
      stringfiedMaxContribute, 
      stringfiedMinContribute,
      convertedToUnixTime
    )
    window.location.reload()
  }

  function validateAmount(value: number) {
  }

  function handleHourSelect(value:any) {
    setEventHourState(value)
  }

  /**
   * 一つはdatepickerからの値
   * もう一つは時間セレクトの値
   * 二つを合算してUNIX変換
   */
  function convertToUnix(value: Date, hour: number) {
    if (value) value.setHours(hour)
    const convertedToUnix = value? value.getTime()/1000 : 0
    return convertedToUnix
  }


  return account ? (
    <>
      <Button mt={4} colorScheme="blue" onClick={onOpen}>Create Event</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>

          <ModalHeader></ModalHeader>
          <Formik
            initialValues={initialSetEventValues}
            onSubmit={(value, actions) => {
              setTimeout(() => {
                handleSetEvent(value)
                actions.setSubmitting(false)
              }, 1000)
            }}
          >
            {(props) => (
              <Form>
                <ModalBody>
                  <Field name="eventName" validate={validateAmount}>
                    {({ field, form }: FieldProps) => (
                      <FormControl isInvalid={Boolean(form.errors.eventName) && Boolean(form.touched.eventName)} >
                        <FormLabel htmlFor="eventName">Event Name</FormLabel>
                        <Input {...field} id="eventName" placeholder="Enter" />
                        <FormErrorMessage>{form.errors.eventName}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="depositToken" validate={validateAmount}>
                    {({ field, form }: FieldProps) => (
                      <FormControl isInvalid={Boolean(form.errors.depositToken) && Boolean(form.touched.depositToken)} >
                        <FormLabel htmlFor="depositToken">Deposit Token</FormLabel>
                        <Input {...field} id="depositToken" placeholder="Enter" />
                        <FormErrorMessage>{form.errors.depositToken}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="hardCap" validate={validateAmount}>
                    {({ field, form }: FieldProps) => (
                      <FormControl isInvalid={Boolean(form.errors.hardCap) && Boolean(form.touched.hardCap)} >
                        <FormLabel htmlFor="hardCap">Hard Cap</FormLabel>
                        <Input {...field} id="hardCap" placeholder="Enter" />
                        <FormErrorMessage>{form.errors.hardCap}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="maxContribute" validate={validateAmount}>
                    {({ field, form }: FieldProps) => (
                        <FormControl isInvalid={Boolean(form.errors.maxContribute) && Boolean(form.touched.maxContribute)}>
                          <FormLabel htmlFor="maxContribute">Max Contribute</FormLabel>
                          <Input {...field} id="maxContribute" placeholder="Enter" />
                          <FormErrorMessage>{form.errors.maxContribute}</FormErrorMessage>
                        </FormControl>
                      )}
                  </Field>
                  <Field name="minContribute" validate={validateAmount}>
                    {({ field, form }: FieldProps) => (
                        <FormControl isInvalid={Boolean(form.errors.minContribute) && Boolean(form.touched.minContribute)} >
                          <FormLabel htmlFor="minContribute">Min Contribute</FormLabel>
                          <Input {...field} id="minContribute" placeholder="Enter" />
                          <FormErrorMessage>{form.errors.minContribute}</FormErrorMessage>
                        </FormControl>
                      )}
                  </Field>
                  <Field name="fCFSTimer" validate={validateAmount}>
                    {({ field, form }: FieldProps) => (
                        <FormControl isInvalid={Boolean(form.errors.fCFSTimer) && Boolean(form.touched.fCFSTimer)} >
                          <FormLabel htmlFor="fCFSTimer">FCFS Timer</FormLabel>
                          <Box display="flex" alignItems="left">
                            <DatePickerField name="fCFSTimer" />
                            <Select value={eventHourState} onChange={e => handleHourSelect(e.target.value)}>
                              { options.map((option:any)  => (
                                <option key={option} value={option}>
                                  {option} :00
                                </option>
                              )) }
                            </Select>
                            <Input {...field} id="fCFSTimer" value={convertToUnix(form.values.fCFSTimer, eventHourState)} />
                          </Box>
                          <FormErrorMessage>{form.errors.fCFSTimer}</FormErrorMessage>
                        </FormControl>
                      )}
                  </Field>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="green"
                    isLoading={props.isSubmitting}
                    type="submit"
                  >
                    Create
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  ): <></>
}