import {
  ChakraProvider,
  useDisclosure,
  Image,
  Spacer
} from "@chakra-ui/react";
import theme from "./theme";
import Layout from "./components/Layout";
import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";
import CreateEventModal from "./components/CreateEvent";
import MainItem from "./components/MainItem";
import EventListTable from "./components/EventList";
import Header from "./components/Header";
import "@fontsource/inter";
import { useEthers } from "@usedapp/core";
import React, { useState, useEffect } from 'react'

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { account, error } = useEthers();
  const [activateError, setActivateError] = useState('')
  useEffect(() => {
    if (error) {
      setActivateError(error.message)
    }
  }, [error])
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Layout>
        <Spacer />
        <ConnectButton handleOpenModal={onOpen} />
        <AccountModal isOpen={isOpen} onClose={onClose} />
        <MainItem account={account} />
        <CreateEventModal account={account} />
        <EventListTable account={account} mb={10} />
      </Layout>
    </ChakraProvider>
  )
}

export default App;
