import { useState } from 'react'
import { useField, useFormikContext } from "formik"
import DatePicker from "react-datepicker"
import { CalendarIcon } from "@chakra-ui/icons"

export const DatePickerField = ({ ...props }:(any)) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <>
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        dateFormat="dd-MM-yyyy"
        onChange={val => {
          setFieldValue(field.name, val);
        }}
        customInput={<CalendarIcon w={4} h={4} onClick={props.onClick}/>}
      />

    </>
  );
};