
export default function Header() {
  return (
    <>
      <header>
        <div className="headlineWrap">
        </div>
      </header>

      <style jsx global></style>
    </>
  )
}