import { Formik, Form, Field, FieldProps } from 'formik'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage
} from "@chakra-ui/react"
import { useRefund, useEventList, useGetUserData } from "../hooks"
import { BigNumber } from 'bignumber.js'


export default function RefundModal( props: any ) {
  // 値がdecimal18桁なのでこの定数で割って調整
  const convertedNum = 10**6

  const { isOpen, onOpen, onClose } = useDisclosure()
  const eventId = props.eventId
  const eventData = useEventList(eventId)
  // イベントに送金した額
  const depositAmount = props.depositAmount
  const { state, send: sendRefund } = useRefund(0,'0')

  // 送金
  async function handleRefund(value: any) {
    let bigNum = new BigNumber(value.refundAmount * convertedNum)
    const stringfiedNum = bigNum.toFixed().toString()
    
    await sendRefund(eventId, stringfiedNum)
    console.log(state)
    // レンダリングのタイミングでエラーになるので一旦リロード
    if (state.status !== "None") {
      window.location.reload()
    }
  }
  
  // 送金額の上限と下限チェック
  function validateAmount(value:number) {
    if (value === 0 || !value ) return
    // const minLimit = parseInt(eventData[5])
    // const inputValue = value * convertedNum
    let error
    // if ( inputValue < depositAmount ) {
    //   error = "Jeez! Too hight refund 😱"
    // } else if ( depositAmount - inputValue < minLimit ) {
    //   error = "Jeez! Too low refund 😱"
    // }
    return error
  }


  return (
    <>
      <Button onClick={onOpen}>Refund</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{ refundAmount: '' }}
            onSubmit={(value, actions) => {
              setTimeout(() => {
                handleRefund(value)
                actions.setSubmitting(false)
              }, 1000)
            }}
          >
          {(props) => (
            <Form>
              <ModalHeader></ModalHeader>
              <ModalBody>
                <Field name="refundAmount" validate={validateAmount}>
                  {({ field, form } : FieldProps) => (
                    <FormControl isInvalid={Boolean(form.errors.refundAmount) && Boolean(form.touched.refundAmount)}>
                      <FormLabel htmlFor="refundAmount">Refund Amount</FormLabel>
                      <Input {...field} id="refundAmount" placeholder="Enter" />
                      <FormErrorMessage>{form.errors.refundAmount}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button 
                  colorScheme="green"
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  Withdraw
                </Button>
              </ModalFooter>
            </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}
