import {
  Flex,
  Text
} from "@chakra-ui/react";
import { useEffect, useState } from "react"
import { useGetAllUserData } from "../hooks";
import { BigNumber } from 'bignumber.js';
import { comma } from "../constant/comma"
import { usePapaParse } from 'react-papaparse';

type CSVData = {
  id?: string
  address?: string
  amount?: string
}

export default function MainItem(props: any) {
  //state初期化
  const [jsonData, setJsonData] = useState([]);
  // 値がdecimal18桁なのでこの定数で割って調整
  const convertedNum = 10 ** 6
  const account = props.account
  const yourDataList = useGetAllUserData(account)

  let sumDeposit = 0
  if (yourDataList && yourDataList[0]) {
    yourDataList[0].map(function (val: any) {
      const deposit = new BigNumber(parseInt(val[2]))
      const convertedDeposit = parseInt(deposit.toFixed()) / convertedNum
      // console.log(convertedDeposit)
      /**
       * valの中身
       * 0: イベントID
       * 1: イベント名
       * 2: アカウントユーザーの送金総額
       */
      sumDeposit += convertedDeposit
      return sumDeposit
    })
  }

  //データ取得関数
  const { readRemoteFile } = usePapaParse();
  const getCsvData = () => {
    readRemoteFile("csv/amount.csv", {
      complete: (results: any) => {
        setJsonData(results.data);
      },
      header: true,
      skipEmptyLines: true,
      download: true,
    });
  };

  const pickUpTargetID = (account: string, val: Array<CSVData>) => {
    if (!account || !val) return
    const dataList = val.filter((val: any) => {
      return String(val.address) === String(account)
    })
    return dataList[0] ? dataList[0].id : ''
  }

  //画面表示時にデータ取得
  useEffect(() => {
    getCsvData();
  }, []);

  return (
    <Flex direction="column" align="center" mt="4">
      <Text color="white" fontSize="2xl">
        ID
      </Text>
      <Text color="white" fontSize="2xl">
        {jsonData.length ? pickUpTargetID(account, jsonData) : ''}
      </Text>
      <Text color="white" fontSize="2xl">
        Total deposit
      </Text>
      <Text color="white" fontSize="4xl">
        {comma(sumDeposit)} USDT
      </Text>
    </Flex>
  );
}