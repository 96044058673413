import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { useContractCall, useContractFunction } from "@usedapp/core"
/** project.solより */
import projectContractAbi from "../abi/ProjectContract.json"; // ABI
import { projectContractAddress, subContractAddress } from "../contracts"; // コントラクト

import subContractAbi from "../abi/SubContract.json"; // ABI

const projectContractInterface = new ethers.utils.Interface(projectContractAbi);
export const projectContract = new Contract(projectContractAddress, projectContractInterface);

const subContractInterface = new ethers.utils.Interface(subContractAbi);
export const subContract = new Contract(subContractAddress, subContractInterface);

/** ########## ProjectContract Methods ########## */
/**
 * イベントIDに基づくイベント情報を取得
 * @param eventId string イベントID
 * @returns eventData イベント情報
 * @author yoshiura
 */
export function useEventList(eventId: Number) {
  const eventData: any = useContractCall({
    abi: projectContractInterface,
    address: projectContractAddress,
    method: "eventList",
    args: [eventId],
  }) ?? [];
  return eventData;
}

/**
 * イベントでユーザーが送金した額を返却
 * @param yourAddress any アドレス stringだと取得中はNanでエラーになる
 * @param eventId Number イベントID
 * @returns depositAmountData イベント情報
 * @author yoshiura
 */
export function useGetUserData(yourAddress: any, eventId: Number) {
  const depositAmountData: any = useContractCall({
    abi: projectContractInterface,
    address: projectContractAddress,
    method: "GetUserData",
    args: [yourAddress, eventId],
  }) ?? [];
  return depositAmountData;
}

/**
 * ユーザーが送金したイベントとその額を返却
 * @param yourAddress any アドレス stringだと取得中はNanでエラーになる
 * @returns depositAmountData イベント情報
 * @author yoshiura
 */
export function useGetAllUserData(yourAddress: string) {
  const depositAmountData: any = useContractCall({
    abi: projectContractInterface,
    address: projectContractAddress,
    method: "GetUserAllData",
    args: [yourAddress],
  }) ?? [];
  return depositAmountData;
}

/**
 * イベントの数を返却する
 * @returns イベントの数
 * @author yoshiura
 */
export function useEventNonce() {
  const [eventNonce]: any = useContractCall({
    abi: projectContractInterface,
    address: projectContractAddress,
    method: "eventNonce",
    args: [],
  }) ?? [];
  return eventNonce;
}

/**
 * コントラクトのオーナーのアドレスを返却
 * @returns アドレス
 * @author yoshiura
 */
export function useOwner() {
  const [owner]: any = useContractCall({
    abi: projectContractInterface,
    address: projectContractAddress,
    method: "owner",
    args: [],
  }) ?? [];
  return owner;
}

/**
 * コントラクトに送金
 * @returns 結果
 * @author yoshiura
 */
export function useDeposit(eventId: number, depositAmount: string) {
  const { state, send } = useContractFunction(
    projectContract, 
    "Deposit", 
    {});
  return { state, send };
}

/**
 * コントラクトからdepositした分から引き出す
 * @returns 結果
 * @author yoshiura
 */
export function useRefund(eventId: number, refundAmount: string) {
  const { state, send } = useContractFunction(
    projectContract, 
    "Refund", 
    {});
  return { state, send };
}

/**
 * コントラクトに送金
 * @returns 結果
 * @author yoshiura
 */
export function useSetEvent() {
  const { state, send } = useContractFunction(
    projectContract, 
    "SetEvent", 
    {});
  return { state, send };
}
/** ########## /ProjectContract Methods ########## */


/** ########## SubContract Methods ########## */
/**
 * コントラクトでユザーが承認されているかを返却
 * 
 * args内の第二引数は集金コントラクトアドレスであることに注意
 * @param yourAddress ユーザーアドレス
 * @returns 0:要approve / 0以外:deposit可能
 * @author yoshiura
 */
export function useAllowance(yourAddress: string | null | undefined) {
  const [allowance]: any = useContractCall({
    abi: subContractInterface,
    address: subContractAddress,
    method: "allowance",
    args: [yourAddress, projectContractAddress],
  }) ?? [];
  return allowance;
}

/**
 * コントラクトでユーザーを承認
 * 
 * args内の第一引数は集金コントラクトアドレスであることに注意
 * @returns result
 * @author yoshiura
 */
export function useApprove(address: string, value: string) {
  const { state, send } = useContractFunction(
    subContract,
    "approve",
    {});
  return { state, send };
}
/** ########## /SubContract Methods ########## */

/**
 * 参考の元記事より未使用
 */
export function useContractMethod(methodName: string) {
  const { state, send } = useContractFunction(projectContract, methodName, {});
  return { state, send };
}

/**
 * 参考元の記事より
 * 参考のためにの残している
 * 未使用
 */
export function useCount() {
  const [count]: any = useContractCall({
    abi: projectContractInterface,
    address: projectContractAddress,
    method: "count",
    args: [],
  }) ?? [];
  return count;
}